import React from "react";
import { PostsList } from "../components/PostsList";
import { RainbowMenu } from "../components/RainbowMenu";
import { graphql } from 'gatsby';
import { Sidebar } from "../components/Sidebar";
import { LogOpen } from "../components/LogOpen";
import { Helmet } from "react-helmet";

import "./styles.scss"

export default function PostsPage({ data }) {

  const posts = data.allMdx.nodes.filter((n) => n.slug.startsWith('posts'))

  return <div>
    <Helmet>
      <title>Galerie</title>
    </Helmet>
    <LogOpen page="posts" />
    <RainbowMenu active="Galerie" relativePath="../"></RainbowMenu>

        <div className="columns">
          <div className="column is-3">
            <Sidebar></Sidebar>
          </div>
          <div className="column is-9">
            <PostsList posts={posts}/>
          </div>
        </div>


  </div>;
}

export const pageQuery = graphql`
{
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    ) {
    nodes {
      id
      excerpt
      slug
      frontmatter {
        title
        date
        thumbnail: image {
          childImageSharp {
            gatsbyImageData(
              width: 200
            )
          }
        }
        full: image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}

`
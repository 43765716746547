import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const PostsList = ({ posts }) => {

  return <>
    <h2 className="title is-4">Galerie</h2>
    <div className="columns is-multiline">
      {posts.map((post) => (
        <div key={post.frontmatter.title} className="column is-3">
          <div className="box p-2 has-background-light">
            <a href={'/' + post.slug} >
              <GatsbyImage image={getImage(post.frontmatter.thumbnail)} alt={post.frontmatter.title} />
              <div className="is-family-sans-serif">
                {post.frontmatter.title}
              </div>
            </a>
          </div>
        </div>
      )
      )}
    </div>
  </>
}